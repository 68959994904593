<style scoped>
img.spinner {
  width: 20px;
  margin-right: 10px;
}

.select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-select-coreui {
  appearance: none;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.25rem 1.5rem 0.25rem 0.5rem;
  font-size: 0.875rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 95%;
  height: 28px;
  margin-left: 15px;
}

.custom-select-coreui:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select-coreui option {
  font-size: 1rem;
  color: #495057;
}

.custom-select-icon {
  position: absolute;
  top: 30%;
  right: 1px;
  pointer-events: none;
  transform: translateY(-50%);
  border: solid #6c757d;
  border-width: 0 2px 2px 0;
  padding: 4px;
  transform: rotate(45deg);
}

.form-group {
  margin-bottom: 1rem;
}

label {
  margin-bottom: 0.5rem;
  color: #212529;
  font-weight: 600;
}

img.spinner {
  width: 20px;
  margin-right: 10px;
}
</style>
<template>
  <modal name="perfilCreate" height="auto" width="900" :scrollable="true" :clickToClose="false">
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab :title="`Criar ${descSingular}`">
            <div class="form">
              <div class="xrow">
                <div class="col-2-3">
                  <div class="form-group">
                    <label for="">Descrição</label>
                    <input class="form-control" v-model="item.descricao" />
                    <div class="message">{{ validation.firstError('item.descricao') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Ativo</label>
                    <select class="form-control" v-model="item.ativo">
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                    <div class="message">{{ validation.firstError('item.ativo') }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="xrow">
              <div class="col-1-1">
                <div class="form-group form-group-inline">
                  <label>Disponível para os tipos: </label>
                  <div>
                    <div
                        v-for="tipoColaborador in tiposColaborador"
                        :key="tipoColaborador.id"
                        class="button checkbox"
                        style="max-height: 35px;"
                    >
                      <input
                          type="checkbox"
                          name="toggle"
                          :value="tipoColaborador.id"
                          v-on:change="handleTipoColaborador"
                      />
                      <span>&emsp;{{ tipoColaborador.desc }}</span>

                      <div v-if="tipoColaborador.chave === 'CLIENTE'">
                        <div class="select-wrapper">
                          <select v-model="item.flags" class="form-control custom-select-coreui">
                            <option :value="null">Selecione</option>
                            <option v-for="option in options" :key="option.chave" :value="option.chave" style="font-weight: 700">
                              {{ option.name }}
                            </option>
                          </select>
                          <i class="custom-select-icon"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
    <div class="modal-footer">
      <button :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }" v-on:click="handleSubmit">
        Inserir
      </button>
      <button class="button" v-on:click="close">Fechar</button>
    </div>
  </modal>
</template>
<script>
import { Validator } from "simple-vue-validator";
import _findIndex from "lodash/findIndex";
import _remove from "lodash/remove";
import CloseModal from "../../components/CloseModal";

const initial = () => ({
  fk_sistema: "",
  descricao: "",
  ativo: 1,
  perfisTipos: [],
});

export default {
  name: "perfilCreate",
  props: ["tiposColaborador", "create", "close", "descSingular"],
  components: { CloseModal },
  data() {
    return {
      item: initial(),
      options: [
        { name: 'Subcliente', chave: 'SUBCLIENTE' },
      ],
    };
  },
  validators: {
    "item.descricao": (value) => Validator.value(value).required(),
    "item.ativo": (value) => Validator.value(value).required(),
  },
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          const item = this.item;
          this.item = initial();
          this.create(item);
        }
      });
    },
    handleTipoColaborador(e) {
      const value = e.target.value;
      const index = _findIndex(this.item.perfisTipos, (t) => +t === +value);
      if (index >= 0) {
        _remove(this.item.perfisTipos, (t) => +t === +value);
      } else {
        this.item.perfisTipos.push(+value);
      }
    },
  },
};
</script>
